import CONFIG from '../globals/config';
import UrlParser from '../routes/urlParser';
import RestaurantDataSource from '../data/restaurantDataSource';
import FavoriteRestaurantIdb from '../data/favoriteRestaurantData-Idb';
import LikeButtonPresenter from '../utils/likeButton-Presenter';
import ReviewFormInitiator from '../utils/reviewFormInitiator';
import '../components/ReviewCard';

const Detail = {
  async render() {
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    return `
      <article id="article-${url.id}"></article>
    `;
  },

  async afterRender() {
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const hasData = await RestaurantDataSource.detailRestaurant(url.id);
    const article = document.querySelector(`#article-${url.id}`);

    if (!hasData.error) {
      const {
        address,
        categories,
        city,
        customerReviews,
        description,
        menus,
        name,
        pictureId,
        rating,
      } = hasData.restaurant;

      article.innerHTML = `
        <header class="article-header">
          <img
            class="lazyload"
            data-src="${CONFIG.BASE_IMAGE_URL_SMALL}${pictureId}"
            alt=""/>
          <div>
            <h2 class="article-title">
              ${name}
            </h2>
            <div class="article-meta">
              <p class="resto-rating"
                style="--rating: ${rating};"
                aria-label="Rating of this restaurant is ${rating} out of 5."
                role="img">
              </p>
              <p>${address}, ${city}</p>
            </div>
          </div>
        </header>
        <div class="article-content">
          <div id="article-image" class="skeleton">
            <img
              class="lazyload"
              data-src="${CONFIG.BASE_IMAGE_URL}${pictureId}"
              src=""
              alt="${name}"
            />
          </div>
          <div class="article-cards">
            <div>
              <label>GENERAL INFO</label>
              <div>
                <p>
                  <span>${name}</span>
                </p>
                <div class="flex">
                  <div><i class="fa fa-fw fa-clock-o"></i></div>
                  <div>Every day from 10 am to 09 pm</div>
                </div>
                <div class="flex">
                  <div><i class="fa fa-fw fa-phone"></i></div>
                  <div>0819912345678</div>
                </div>
                <div class="flex">
                  <div><i class="fa fa-fw fa-map-marker"></i></div>
                  <div>${address}<br>${city}</div>
                </div>
              </div>
            </div>
            <div>
              <label>CATEGORY</label>
              <ul>
                ${categories.map((category) => `<li>${category.name}</li>`)
      .join('')}
              </ul>
            </div>
            <div>
              <label>FOOD MENU</label>
              <ul>
                ${menus.foods.map((food) => `<li>${food.name}</li>`).join('')}
              </ul>
            </div>
            <div>
              <label>BEVERAGE</label>
              <ul>
                ${menus.drinks.map((drink) => `<li>${drink.name}</li>`)
      .join('')}
              </ul>
            </div>
          </div>
          <p>
            ${description}
            <br/>
            <span id="like-button-container"></span>
          </p>
        </div>
        <footer class="review-card">
          <div>
            <div class="section-header">
              <h3>CUSTOMER REVIEW</h3>
              <div></div>
            </div>
            <div id="customer-review-list">
              ${customerReviews.map((review) => {
    return `<review-card
                  name="${review.name}"
                  date="${review.date}"
                  review="${review.review}"
                  ></review-card>`;
  }).join()}
            </div>
            <form id="review-form"></form>
          </div>
        </footer>
      `;
      LikeButtonPresenter.init({
        likeButtonContainer: document.querySelector('#like-button-container'),
        favoriteRestaurants: FavoriteRestaurantIdb,
        restaurant: hasData.restaurant,
      });

      ReviewFormInitiator.init({
        reviewFormContainer: document.querySelector('#review-form'),
        restaurantId: url.id,
      });
    } else {
      article.innerHTML = `<p class="text-center">
        <br/><br/><br/><br/><br/><br/><br/><br/>
        <i
          class="fa fa-exclamation-triangle"
        ></i> ${hasData.message}</p>
        <br/><br/>`;
    }
  },
};

export default Detail;
