import {restaurantItem} from '../../templates';

class FavoriteRestaurantSearchView {
  getTemplate() {
    return `
      <section
        id="welcome-block"
        class="article-header"
        ">
        <picture>
          <source
            media="(max-width: 480px)"
            srcset="./images/hero-image_4-small.webp">
          <source
            media="(max-width: 768px)"
            srcset="./images/hero-image_4-medium.webp">
          <source
            media="(min-width: 769px)"
            srcset="./images/hero-image_4-large.webp">
          <source
            media="(max-width: 480px)"
            srcset="./images/hero-image_4-small.jpg">
          <source
            media="(max-width: 768px)"
            srcset="./images/hero-image_4-medium.jpg">
          <img
            class="lazyload"
            data-src="./images/hero-image_4-large.jpg"
               alt="">
        </picture>
        <div>
          <h2>
            Your <i class="fa fa-fw fa-thumbs-up"></i> <span>Favorite</span>
            Restaurants
          </h2>
        </div>
      </section>
      <section id="search-fav-container">
        <input id="query"
          type="text"
          placeholder="Type to search..."
          aria-label="Search for your favorite restaurants"
          >
      </section>
      <section id="resto-list">
        <div id="resto-list-items"></div>
      </section>
   `;
  }

  runWhenUserIsSearching(callback) {
    document.getElementById('query').addEventListener('input', (event) => {
      callback(event.target.value);
    });
  }

  showRestaurants(restaurants) {
    this.showFavoriteRestaurants(restaurants);
  }

  showFavoriteRestaurants(restaurants = []) {
    let html;
    if (restaurants.length) {
      html = restaurants.reduce((carry, restaurant) => carry
          .concat(restaurantItem(restaurant)), '');
    } else {
      html = this._getEmptyRestaurantTemplate();
    }

    document.getElementById('resto-list-items').innerHTML = html;

    document.getElementById('resto-list-items')
        .dispatchEvent(new Event('resto-list-items:updated'));
  }

  _getEmptyRestaurantTemplate() {
    return `<p
      class="resto-item__not__found resto__not__found"
      >Tidak ada restaurant untuk ditampilkan
    </p>`;
  }
}

export default FavoriteRestaurantSearchView;
